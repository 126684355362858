import React, { useEffect, useMemo, useState } from 'react';
import { Field, reduxForm, change, getFormValues } from 'redux-form';
import { Col, Row, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TimetableIcon from 'mdi-react/TimetableIcon';
import moment from 'moment';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
import renderField from '../../../shared/components/custom/Field';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import renderSelectField from '../../../shared/components/form/Select';
import validate from './validateDetails';
import t, { partial } from '../../../util/translation/translation';
import renderDropZoneField from '../../../shared/components/form/DropZoneMS';
import renderDropZoneVideoField from '../../../shared/components/form/DropZoneVideo';
import { getImageHash } from '../../../util/functions';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';
import FormBox from '../../../shared/components/custom/FormBox';

const NewsForm = (props) => {
  const {
    handleSubmit, news, initialize, newsID, categories,
  } = props;
  const categoryOptions = useMemo(() => categories.map((item) => ({ label: item.name, value: item.id })), [categories]);
  const [previousNewsId, setPreviousNewsId] = useState(null);
  const p = partial('NewsForm');
  const s = partial('shared');

  const getImageHeaderType = (_news) => {
    if (_news.headingVideo) {
      return {
        label: t('questionaire.imageHeaderType-VIDEO'),
        value: 'VIDEO',
      };
    }
    if (_news.headingImage) {
      return {
        label: t('questionaire.imageHeaderType-IMAGE'),
        value: 'IMAGE',
      };
    }
    return null;
  };
  useEffect(() => {
    if (news && (newsID !== previousNewsId || !previousNewsId)) {
      const newsCategories = news.categories.map((item) => item.id);
      initialize({
        title: news.title,
        description: news.description,
        categories: categoryOptions.filter((item) => newsCategories.includes(item.value)),
        author: news.author,
        publicationDate: moment(news.publicationDate).format('YYYY-MM-DDTHH:mm'),
        expirationDate: moment(news.expirationDate).format('YYYY-MM-DDTHH:mm'),
        externalLink: news.externalLink,
        isPinned: news.isPinned,
        // commentsEnabled: news.commentsEnabled,
        likesEnabled: news.likesEnabled,
        headingImage: news.headingImage ? {
          name: 'test name',
          preview: news.headingImage,
          croppedImage: news.headingImage,
          hash: getImageHash(news.headingImage),
        } : null,
        headingVideo: news.headingVideo ? {
          preview: news.headingVideo,
        } : null,
        imageHeaderType: getImageHeaderType(news),
        dynamicLink: news.dynamicLink,
      });
      setPreviousNewsId(newsID);
    } else {
      initialize({
        categories: [],
        imageHeaderType: null,
      });
    }
  }, [news, categoryOptions]);
  return (
    <form onSubmit={handleSubmit} className="form">
      <Container>
        <Row>
          <Col lg="12" className="mb-3">
            <FormBox title={s('details')}>
              <div style={{ padding: '10px 10px 10px 10px' }}>
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form">{s('heading')}</span>
                      <div className="form__form-group-field">
                        <Field
                          name="title"
                          component={renderField}
                          placeholder={s('heading')}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form">
                        {p('description')}
                        &nbsp;
                        <TooltipWithIcon id="description-tooltip" text={p('descriptionInfoTooltip')} />
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="description"
                          component={renderField}
                          placeholder={`${p('description')} (max 100 char)`}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form-header">{s('newsCategory')}</span>
                      <Field
                        name="categories"
                        placeholder={s('newsCategory')}
                        options={categoryOptions}
                        multiple
                        component={renderSelectField}
                      />
                      <Link to="/news?tab=2" target="_blank" rel="noopener noreferrer">
                        <span className="form__form-group-field">{p('newsCategoryDescription')}</span>
                      </Link>
                    </div>
                  </Col>
                  <Col>
                    <div className="form__form-group">
                      <span className="form">{s('author')}</span>
                      <div className="form__form-group-field">
                        <Field
                          name="author"
                          component={renderField}
                          placeholder={s('author')}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form__form-group">
                      <span className="form">{s('publicationDate')}</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                        <Field
                          name="publicationDate"
                          component={renderField}
                          type="datetime-local"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="form__form-group">
                      <span className="form">{s('expirationDate')}</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                        <Field
                          name="expirationDate"
                          component={renderField}
                          type="datetime-local"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="form">
                      {`${s('externalLink')}`}
                      &nbsp;
                      <TooltipWithIcon id="external-link-tooltip" text={p('externalLinkDescription')} />
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="externalLink"
                        component={renderField}
                        placeholder={s('externalNewsLink')}
                        disabled={newsID ? (news?.type !== 'custom') : false}
                      />
                    </div>
                  </Col>
                </Row>
                {newsID ?
                  <Row>
                    <Col>
                      <div className="form__form-group">
                        <span className="form-header marginRight-8">{s('universalLink')}</span>
                        <div className="form__form-group-field">
                          <Field name="dynamicLink" component="input" disabled />
                          <button type="button" className="form__form-group-icon" onClick={() => navigator.clipboard.writeText(news.dynamicLink)}>
                            <LinkVariantIcon />
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row> : null}
                <Row>
                  <Col >
                    <div className="form__form-group-field">
                      <Field
                        name="isPinned"
                        component={renderCheckBoxField}
                        label={s('attachedNews')}
                        className="colored-click"
                      />
                    </div>
                  </Col>
                  <Col >
                    <div className="form__form-group-field">
                      <Field
                        name="likesEnabled"
                        component={renderCheckBoxField}
                        label={p('likesEnabled')}
                        className="colored-click"
                      />
                    </div>
                  </Col>
                  {/* <Col >
                    <span className="form">{p('commentsEnabled')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="commentsEnabled"
                        component={renderCheckBoxField}
                        label={p('commentsEnabled')}
                        className="colored-click"
                      />
                    </div>
                  </Col> */}
                </Row>
              </div>
            </FormBox>
          </Col>
          <Col className="mb-3">
            <FormBox title={p('thumbnailImage')}>
              <Col>
                <span className="form-header">{t('questionaire.imageHeaderType-IMAGE')}</span>
                <Field
                  saveBase64Cropped
                  name="headingImage"
                  component={renderDropZoneField}
                  crop="NEWS_THUMB_CROP"
                  displayCrop
                />
                <span className="form-header">{t('questionaire.imageHeaderType-VIDEO')} ({t('NewsPage.replaceImage')})</span>
                <Field
                  name="headingVideo"
                  component={renderDropZoneVideoField}
                />
              </Col>

              {news && news?.type !== 'custom' ?
                <div style={{ padding: '0px 10px 10px 10px' }}>
                  <span className="form">
                    {`${s('externalVideoLink')}`}
                    &nbsp;
                    <TooltipWithIcon id="external-video-link-tooltip" text={s('externalVideoLinkDescription')} />
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="headingVideo"
                      component={renderField}
                      placeholder={s('externalNewsLink')}
                      disabled
                    />
                  </div>
                </div> : null}
            </FormBox>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const mapStateToProps = (state) => ({
  newsData: getFormValues('news_form')(state),
  errors: state.form,
});


export default reduxForm({
  form: 'news_form',
  validate,
})(connect(mapStateToProps, { change })(NewsForm));


