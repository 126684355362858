import React, { useEffect } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { Button, Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import CheckboxMarkedCircle from 'mdi-react/CheckboxMarkedCircleIcon';
import CircleOutline from 'mdi-react/CircleOutlineIcon';
import ImageIcon from 'mdi-react/FileImageIcon';
import { connect } from 'react-redux';
import DescriptionComponent from './SpotlightGroupFormDescription';
import renderField from '../../../shared/components/custom/Field';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import validate from './validateGroupForm';
import t, { partial } from '../../../util/translation/translation';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import { getReadableFormat, getReadableSize, getSpotlightFormats, SPOTLIGHT_SIZES, getIconAmountForSizeFormat, FormatRatios, hasCaption } from '../spotlightUtils';
import TooltipWithIcon from '../../../shared/components/custom/TooltipWithIcon';

const p = partial('HighlightBannerForm');
const s = partial('shared');

const InnerFormatIcon = ({ format, selected, multiple, last }) => {
  const containerStyle = { width: 80, height: 142, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', padding: '10px 0 10px 0', display: 'flex', flexDirection: 'column', marginRight: multiple && !last ? 10 : 0 };
  const innerContainer = { backgroundColor: selected ? '#C3DEFE' : '#D7DAE0', alignItems: 'center', display: 'flex', justifyContent: 'center' };
  switch (format) {
    case 'SQUARE': return (
      <div style={containerStyle}>
        <div style={{ ...innerContainer, height: 80, width: 80 }}>
          <ImageIcon size={20} color={selected ? '#0469DC' : 'black'} />
        </div>
      </div>
    );
    case 'LANDSCAPE': return (
      <div style={containerStyle}>
        <div style={{ ...innerContainer, height: 45, width: 80 }}>
          <ImageIcon size={20} color={selected ? '#0469DC' : 'black'} />
        </div>
      </div>
    );
    case 'PORTRAIT': return (
      <div style={containerStyle}>
        <div style={{ ...innerContainer, height: 142, width: 80 }}>
          <ImageIcon size={20} color={selected ? '#0469DC' : 'black'} />
        </div>
      </div>
    );
    case 'HALF_PORTRAIT': return (
      <div style={containerStyle}>
        <div style={{ ...innerContainer, height: 100, width: 80 }}>
          <ImageIcon size={20} color={selected ? '#0469DC' : 'black'} />
        </div>
      </div>
    );
    case 'CIRCLE': return (
      <div style={containerStyle}>
        <div style={{ ...innerContainer, height: 80, width: 80, borderRadius: 100 }}>
          <ImageIcon size={20} color={selected ? '#0469DC' : 'black'} />
        </div>
      </div>
    );
    case 'BANNER': return (
      <div style={containerStyle}>
        <div style={{ ...innerContainer, height: 25, width: 120, justifyContent: 'flex-start' }}>
          <ImageIcon size={20} color={selected ? '#0469DC' : 'black'} />
        </div>
      </div>
    );
    case 'TEXT_BANNER': return (
      <div style={containerStyle}>
        <div style={{ flexDirection: 'row', display: 'flex' }} >
          <div style={{ background: selected ? 'rgba(65, 129, 255, 0.12)' : '#D7DAE0', height: 36, width: 36, borderRadius: 36, marginRight: 5 }} />
          <div style={{ ...innerContainer, width: 160, backgroundColor: selected ? 'rgba(65, 129, 255, 0.12)' : '#D7DAE0', display: 'flex', flexDirection: 'column', alignItems: 'start', padding: 10, borderRadius: 8 }}>
            <strong style={{ color: selected ? '#0f69b4' : 'black', textAlign: 'start' }}>{s('title')}</strong>
            <p style={{ color: selected ? '#0f69b4' : 'black', textAlign: 'start', marginTop: 4 }}>{s('description')}</p>
          </div>
        </div>
      </div>
    );
    default: return null;
  }
};

const FormatComponent = (size, entityExists) => (data) => {
  const { fieldValue, input: { onChange, value } } = data;
  const isSelected = value === fieldValue;
  const iconAmount = getIconAmountForSizeFormat(size, fieldValue);
  return (
    <Col xs={12} sm={12} md={12} lg={6} className="mt-4">
      <button
        disabled={entityExists}
        style={{
          backgroundColor: isSelected ? '#0469DC10' : 'white',
          borderWidth: 2,
          borderStyle: isSelected ? 'solid' : 'dashed',
          borderRadius: 8,
          borderColor: isSelected ? '#0469DC' : '#D7DAE0',
          opacity: entityExists ? 0.4 : 1,
          width: 360,
          height: 170,
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          padding: 10,
        }}
        onClick={() => onChange(fieldValue)}
        type="button"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <p style={{ color: isSelected ? '#0469DC' : '#D7DAE0', fontSize: 12, fontWeight: 600 }}><strong style={{ color: '#121B2B' }}>{FormatRatios(fieldValue, size)}</strong> - {getReadableFormat(fieldValue)}</p>
          {isSelected ? <CheckboxMarkedCircle size={12} className="icon-min-20" color="#0469DC" /> : <CircleOutline size={12} className="icon-min-20" color="#D7DAE0" />}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          {[...Array(iconAmount).keys()].map((_, idx) => <InnerFormatIcon format={fieldValue} selected={isSelected} multiple={iconAmount > 1} last={idx === iconAmount - 1}/>)}
        </div>
      </button>
    </Col>
  );
};

const SpotlightGroupForm = ({
  handleSubmit, initialize, entity, spotlightGroupForm, change,
}) => {
  useEffect(() => {
    if (entity) {
      initialize({
        title: entity.title,
        format: entity.format,
        size: entity.size,
        descriptionAvailable: entity.descriptionAvailable,
        hideTitle: !entity.titleVisible,
      });
    } else {
      initialize({
        format: 'SQUARE',
        size: 'SMALL',
      });
    }
  }, [entity]);
  const handleSizeChange = () => {
    change('format', 'SQUARE');
  };
return (
  <form className="form" onSubmit={handleSubmit}>
    <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginBottom: 10, width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('title')}</div>
        </div>
      </div>
      <div style={{ padding: '10px' }}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="form__form-group">
                <span className="form-header">{s('title')}*</span>
                <Field
                  name="title"
                  component={renderField}
                  placeholder={s('title')}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form__form-group-field">
                <Field
                  type="checkbox"
                  name="hideTitle"
                  disabled={spotlightGroupForm?.format === 'BANNER' || spotlightGroupForm?.format === 'TEXT_BANNER'}
                  component={renderCheckBoxField}
                  label={t('HighlightBannerPage.hideTitle')}
                  className="colored-click"
                />
                &nbsp;
                <TooltipWithIcon id="no-access-view-tooltip" text={t('HighlightBannerForm.hideTitleInfo')} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    <div style={{ backgroundColor: '#F4F4F6', borderRadius: 4, borderColor: '#D7DAE0', borderStyle: 'solid', borderWidth: 1, marginBottom: 10, width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomStyle: 'dashed', borderColor: '#D7DAE0', padding: '10px 10px 10px 10px' }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div style={{ fontSize: 16, lineHeight: '24px', color: 'black', fontWeight: 'bold' }}>{s('tile')}</div>
        </div>
      </div>
      <div style={{ padding: '10px' }}>
        <Container>
          <Row>
            <Col md={12}>
              <span className="form-header">{t('HighlightBannerPage.size')}</span>
              <div className="radio-horizontal">
                {SPOTLIGHT_SIZES.map((size, idx) => (
                  <Col md={5}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="size"
                          component={renderRadioButtonField}
                          label={getReadableSize(size)}
                          radioValue={size}
                          defaultChecked={idx === 0}
                          onChange={handleSizeChange}
                          disabled={!!entity}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="form-header">{t('HighlightBannerPage.format')}</span>
            </Col>
          </Row>
          <Row style={{ marginTop: -10, paddingBottom: 20 }}>
            {getSpotlightFormats(spotlightGroupForm?.size).map((format) => (
              <Field
                key={format}
                name="format"
                component={FormatComponent(spotlightGroupForm?.size, !!entity)}
                fieldValue={format}
              />
            ))}
          </Row>
          {hasCaption(spotlightGroupForm?.format, spotlightGroupForm?.size) ?
            <div style={{ marginTop: 20 }}>
              <Row>
                <Col>
                  <span className="form-header">{t('HighlightBannerPage.imageText')}</span>
                </Col>
              </Row>
              <Row style={{ marginTop: -10, paddingBottom: 20 }}>
                <Field
                  name="descriptionAvailable"
                  component={DescriptionComponent('IMAGE')}
                  fieldValue="false"
                />
                <Field
                  name="descriptionAvailable"
                  component={DescriptionComponent('IMAGE_TEXT')}
                  fieldValue="true"
                />
              </Row>
            </div> : null}
        </Container>
      </div>
    </div>
    <Container>
      <Row>
        <Col>
          <Button type="submit" color="primary">{entity ? t('shared.save') : t('shared.create')}</Button>
          <Link to="/spotlight"><Button>{t('shared.cancel')}</Button></Link>
        </Col>
      </Row>
    </Container>
  </form>
  );
};
const reduxF = reduxForm({
    form: 'spotlightGroupForm',
    validate,
  });
const mapStateToProps = (state) => ({
  spotlightGroupForm: getFormValues('spotlightGroupForm')(state),
});
export default connect(mapStateToProps)(reduxF(SpotlightGroupForm));

